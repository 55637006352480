import React, { useEffect, useState, useRef } from 'react';
import {
  BookmarkBorder,
  Email,
  InfoRounded,
  KeyboardArrowDown,
  KeyboardArrowUp,
} from '@mui/icons-material';
import {
  Badge,
  Box,
  ClickAwayListener,
  Grow,
  IconButton,
  MenuItem,
  Typography,
  MenuList,
  Paper,
  Popper,
} from '@mui/material';

import Link from '../../ActiveLink';
import LazyImage from '../../LazyImage';

import userMenu from '../../../../content/elements/navbar.json';

import style from './NavRightItemStyle';
import { useAppDispatch, useAppSelector } from '../../../../hooks';
import { logout } from '../../../../actions/auth';
import { openChangePopup } from '../../../../actions/popup';

const NavRightItem = ({}) => {
  const dispatch = useAppDispatch();
  const loginDetails: any = useAppSelector((state) => state.auth);
  const classes = style();

  const [open, setOpen] = useState(false);
  const anchorRef = useRef(null);

  const { userData, userProfile } = loginDetails;

  const handleToggle = () => {
    setOpen(true);
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }

    setOpen(false);
  };

  function handleListKeyDown(event) {
    if (event.key === 'Tab') {
      event.preventDefault();
      setOpen(false);
    }
  }

  // return focus to the button when we transitioned from !open -> open
  const prevOpen = useRef(open);
  useEffect(() => {
    if (prevOpen.current === true && open === false) {
      anchorRef.current.focus();
    }

    prevOpen.current = open;
  }, [open]);

  const isMentorDashboard = loginDetails?.userData?.roles?.includes('MENTOR');
  const isCompanyDashboard =
    loginDetails?.userData?.roles?.includes('HIRING_PARTNER');
  //

  return (
    <Box
      display="flex"
      height="100%"
      alignItems="center"
      marginRight={{ sm: '13px' }}
    >
      {loginDetails.isAuthenticated && isCompanyDashboard ? (
        <Link
          activeClassName={classes.courseActive}
          href={`/hiring/applications`}
        >
          <span className={classes.myCourse}>
            <span>{'Company Dashboard'}</span>
          </span>
        </Link>
      ) : loginDetails.isAuthenticated && isMentorDashboard ? (
        <Link activeClassName={classes.courseActive} href={`/dashboard/mentor`}>
          <span className={classes.myCourse}>
            <span>{'Mentor Dashboard'}</span>
          </span>
        </Link>
      ) : loginDetails.isAuthenticated ? (
        <Link activeClassName={classes.courseActive} href={`/learner-hub`}>
          <span className={classes.myCourse}>
            <BookmarkBorder fontSize="small" /> <span>{'My Courses'}</span>
          </span>
        </Link>
      ) : (
        ''
      )}

      <Box {...iconBox}>
        {/* <Link title="notification">
          <IconButton
            className={classes.iconButton}
            {...iconsBtn}
            style={{ position: 'relative' }}
          >
            <Badge color="primary">
              <NotificationsNone />
            </Badge>
          </IconButton>
        </Link> */}
      </Box>
      <Box
        {...iconBox}
        display={{ xs: 'none', sm: 'flex' }}
        onClick={handleToggle}
        ref={anchorRef}
        aria-controls={open ? 'menu-list-grow' : undefined}
        aria-haspopup="true"
      >
        <IconButton
          disableFocusRipple
          disableRipple
          color="inherit"
          aria-label="open drawer"
          className={classes.customeIconButton}
        >
          <Box
            mr={2}
            width="40px"
            height="40px"
            borderRadius="50%"
            className={classes.userProfileBox}
            overflow="hidden"
          >
            {userProfile.profileMedia &&
            userProfile.profileMedia[0] &&
            userProfile.profileMedia[0].s3Location ? (
              <img
                src={`${process.env.NEXT_PUBLIC_ASSETS_2}/${userProfile.profileMedia[0]?.s3Location.path}`}
                alt={userProfile.firstName}
              />
            ) : (
              <LazyImage
                src={`/images/icons/man2.png`}
                alt={userProfile.firstName}
              />
            )}
          </Box>
          <Badge
            badgeContent={
              !userData?.emailVerifiedAt ? (
                <InfoRounded
                  className={classes.infoIcon}
                  fontSize="small"
                  htmlColor="#b00020"
                />
              ) : (
                ''
              )
            }
          >
            <Typography variant="body1" color={open ? 'primary' : 'initial'}>
              {userProfile.firstName}
            </Typography>
          </Badge>

          {open ? <KeyboardArrowUp color="primary" /> : <KeyboardArrowDown />}
        </IconButton>

        {/* <DashboardDropdown /> */}

        <Popper
          open={open}
          anchorEl={anchorRef.current}
          role={undefined}
          transition
          disablePortal
        >
          {({ TransitionProps, placement }) => (
            <Grow
              {...TransitionProps}
              style={{
                transformOrigin: placement === 'bottom' ? '' : 'center bottom',
              }}
            >
              <Paper className={classes.dropdownBox}>
                <ClickAwayListener onClickAway={handleClose}>
                  <MenuList
                    autoFocusItem={open}
                    id="menu-list-grow"
                    onKeyDown={handleListKeyDown}
                  >
                    {!loginDetails.userData?.emailVerifiedAt && (
                      <MenuItem
                        onClick={() => {
                          console.log('verify email popup');
                          dispatch(openChangePopup('VerifyEmailPopup'));
                        }}
                      >
                        <div className={classes.verifyEmail}>
                          <Email htmlColor="#b00020" />
                          <div>
                            <Typography variant="subtitle1">
                              Verify your email
                            </Typography>
                            <Typography variant="caption" component="p">
                              Be part of our trusted <br />
                              community by verifying email id.
                            </Typography>
                          </div>
                        </div>
                      </MenuItem>
                    )}

                    {isMentorDashboard && loginDetails.isAuthenticated && (
                      <Link href="/dashboard/mentor">
                        <MenuItem onClick={handleClose}>
                          {'Mentor Dashboard'}
                        </MenuItem>
                      </Link>
                    )}

                    {isCompanyDashboard && loginDetails.isAuthenticated && (
                      <Link href={`/hiring/applications`}>
                        <MenuItem onClick={handleClose}>
                          {'Company Dashboard'}
                        </MenuItem>
                      </Link>
                    )}

                    {userMenu.userMenu.map((menu, key) => (
                      <Link href={menu.url} key={key}>
                        <MenuItem onClick={handleClose}>{menu.name}</MenuItem>
                      </Link>
                    ))}
                    <Link href={`/user-profile?userId=${loginDetails.userId}`}>
                      <MenuItem onClick={handleClose}>{'Profile'}</MenuItem>
                    </Link>
                    <MenuItem onClick={() => dispatch(logout())}>
                      Sign Out
                    </MenuItem>
                  </MenuList>
                </ClickAwayListener>
              </Paper>
            </Grow>
          )}
        </Popper>
      </Box>
    </Box>
  );
};

const iconBox = {
  minWidth: { xs: '56px' },
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%',
};

export default NavRightItem;
